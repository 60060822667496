import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/logout',
        name: 'logout',
        component: Unauthorized
    },
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    
      {
        path: "/TimeTracking/AppsAndWebsites",
        name: "UsedApplicationListing",
        component: () => import("../views/TimeTracking/UsedApplicationListing.vue"),
        meta: {
          title: "Apps and Websites",
          breadcrumb: [
            { name: "Time Tracking"},
            { name: "Apps and Websites"}
          ],
        },
      },

      {
        path: "/TimeTracking/TimetrackDashBoard",
        name: "TimetrackDashboard",
        component: () => import("../views/TimeTracking/TimetrackDashBoard.vue"),
        meta: {
          title: "Time Track DashBoard",
         breadcrumb: [{ name: "Time Track DashBoard" }],
        },
      },
      
      {
        path: "/TimeTracking/TimetrackDashBoard/:id",
        name: "TimetrackDashBoardById",
        component: () => import("../views/TimeTracking/TimetrackDashBoard.vue"),
        meta: {
          title: "Time Track DashBoard",
         breadcrumb: [{ name: "Time Track DashBoard" }],
        },
      },
      {
        path: "/TimeTracking/DepartmentViewDasboard",
        name: "DepartmentViewDashboard",
        component: () => import("../views/TimeTracking/DepartmentViewDasboard.vue"),
        meta: {
          title: "Department View Dashboard",
         breadcrumb: [{ name: "Department View Dashboard" }],
        },
      },
      {
        path: "/TimeTracking/Category",
        name: "CategoryListing",
        component: () => import("../views/TimeTracking/CategoryListing.vue"),
        meta: {
          title: "Category Listing",
         breadcrumb: [{ name: "Category Listing" }],
        },
      },
      {
        path: "/TimeTracking/History",
        name: "HistoryListing",
        component: () => import("../views/TimeTracking/HistoryListing.vue"),
        meta: {
          title: "History Listing",
          breadcrumb: [{ name: "History Listing" }],
          checkPrivilege:false,
        },
      },
      {
        path: "/TimeTracking/ImportCategory",
        name: "ImportCategory",
        component: () => import("../views/TimeTracking/ImportCategory.vue"),
        meta: {
          title: "Import Category",
          breadcrumb: [{ name: "Import Category" }],
          checkPrivilege:false,
        },
      },
      {
        path: "/TimeTracking/LiveMonitoring",
        name: "LiveMonitoring",
        component: () => import("../views/LiveMonitoring/List.vue"),
        meta: {
          title: "Live Monitoring",
          breadcrumb: [{ name: "Live Monitoring" }]
        }
      },
      {
        path: "/Timer/Index",
        name: "TimeTrack",
        component: () => import("../views/TimeTracking/TimeTrack.vue"),
        meta: {
          title: "Time Tracking",
          breadcrumb: [{ name: "Time Tracking" }],
        },
      },
      
      {
        path: "/Project/ToDoList",
        name: "ToDoList",
        component: () => import("../views/TimeTracking/ToDoList.vue"),
        meta: {
          title: "To Do List",
          breadcrumb: [{ name: "To Do List" }],
        },
      },
      {
        path: "/WorkDiary/Index",
        name: "WorkDiary",
        component: () => import("../views/TimeTracking/WorkDiaryListing.vue"),
        meta: {
          title: "Work Diary Listing",
          breadcrumb: [{ name: "Work Diary" }],
        },
      },
      {
        path: "/TimeTracking/FlaggedScreenshot",
        name: "FlaggedScreenshot",
        component: () => import("../views/TimeTracking/FlaggedScreenshotListing.vue"),
        meta: {
          title: "Flagged Screenshot Listing",
          breadcrumb: [{ name: "Flagged ScreenShots" }],
        },
      },
      {
        path: "/TimeTracking/BulkTimeEntry",
        name: "BulkTimeEntry",
        component: () => import("../views/TimeTracking/BulkTimeEntry.vue"),
        meta: {
          title: "Bulk Time Entry",
          breadcrumb: [{ name: "Bulk Time Entry" }],
        },
      },
      {
        path: "/TimeTracking/BulkEdit",
        name: "Bulk Edit",
        component: () => import("../views/TimeTracking/BulkEdit.vue"),
        meta: {
          title: "Bulk Edit",
          breadcrumb: [{name: "Bulk Edit"}]
        }
      },
      {
        path: "/TimeTracking/UserProductivity",
        name: "UserProductivity",
        component: () => import("../views/TimeTracking/UserProductivity.vue"),
        meta: {
          title: "User Productivity",
          breadcrumb: [{ name: "User Productivity" }],
        },
      },      
      {
        path: "/Timesheet/Index",
        name: "Timesheet",
        component: () => import("../views/TimeTracking/TimeSheet.vue"),
        meta: {
          title: "Timesheet",
          breadcrumb: [{name: "Timesheet"}],
          checkPrivilege:false,
        }
        
      },
      
      {
        path: "/TimerReports/Index",
        name: "Index",
        component: () => import("../views/TimerReports/Index"),
        meta: {
          title: "Silent App Report",
          breadcrumb: [{ name: "Timer Reports" }],
        },
      },
      {
        path: "/Timer/TrackEntryFlag",
        name: "TrackFlag",
        component: () => import("../views/TimeTracking/TrackEntryFlag"),
        meta: {
          title: "Flag Track Entry",
          breadcrumb: [{name: "Flag Track Entry"}],
          checkPrivilege:false,
        }
        
      },
      {
        path: "/TimeApproval/Index",
        name: "TimeApproval",
        component: () => import("../views/TimesheetApproval/TimesheetApproval.vue"),
        meta: {
          title: "TimesheetApproval",
          breadcrumb: [{ name: "Timesheet Approvals" }],
          checkPrivilege:false,
        },
      },
      {
        path: "/TimeApproval/History",
        name: "ApprovalHistory",
        component: () => import("../views/TimesheetApproval/TimesheetApprovalHistory.vue"),
        meta: {
          title: "TimesheetHistory",
          breadcrumb: [{ name: "Timesheet History" }],
        },
      },
      {
        path: "/Timer/AdvancedSetup",
        name:"AdvanceSetup",
        component:()=> import("../views/AdvanceSetup/AdvancedSetting.vue"),
        meta:{
          title: "Advanced Setting",
          breadcrumb:[{name: "Advanced Setting"}],
          checkPrivilege: false,
          
        },
      },
      {
        path: "/Week/manage",
        name:"WorkingWeek",
        component:()=> import("../views/WorkingWeek/WorkingWeek.vue"),
        meta:{
          title: "Working Week",
          breadcrumb:[{name: "Working Week"}],
          checkPrivilege: false,
          
        },
      },
      {
        path: "/Week/WorkingDay",
        name:"WorkingDay",
        component:()=> import("../views/WorkingDay/Manage.vue"),
        meta:{
          title: "Working Day",
          breadcrumb:[{name: "Working Day"}],
          checkPrivilege: false,
          
        },
      },
      {
        path: "/Week/Add/:id?",
        name:"AddWorkingDay",
        component:()=> import("../views/WorkingDay/Add.vue"),
        meta:{
          title: "Working Day",
          breadcrumb:[{name: "Working Day"}],
          checkPrivilege: false,
          
        },
      },
      {
        path: "/HolidayBank/Index",
        name:"HolidayBank",
        component:()=> import("../views/AdvanceSetup/HolidayBank.vue"),
        meta:{
          title: "Holiday Bank",
          breadcrumb:[{name: "Holiday Bank"}],
          checkPrivilege: false,
          
        },
      },
      {
        path: "/tag/add",
        name:"Addtag",
        component:()=> import("../views/AdvanceSetup/ManageTags.vue"),
        meta:{
          title: "Add tag",
          breadcrumb:[{name: "Manage"}],
          checkPrivilege: false,
          
        },
      },
      
      
      
      
      
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
  document.title = "TALYGEN - " + (to.meta.title || DEFAULT_TITLE);
  document.getElementById("divProgress1").style.display = "none";
  document.getElementById("talygenfooter").style.display = "block";  
  
  if (
    to.path == "/callback" ||
    to.path == "/unauthorized" ||
    to.path == "/logoutcallback"
  ) {
    next();
  } else {
    if (to.path == "/logout") {
      authService.logOut();
    } else {
      // if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_ACCOUNTS')>-1){
      //   to.meta.breadcrumb=[{name:"Accounts", link:"/CRM/Accounts"},
      //   {name: "Import Account details"}]
      // }else if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_CONTACTS')>-1){
      //     to.meta.breadcrumb=[{name:"Contact", link:"/CRM/ContactListing"},
      //     {name: "Import Contacts details"}]
      // }
      const ustore = useloggedUserInfo();
      let app = ustore.GetUserInfo;
      if (app) {
        let isCheckPrivilege = true;
        if(to.meta.hasOwnProperty('checkPrivilege'))
        {
            isCheckPrivilege = to.meta.checkPrivilege;
        }
        if(isCheckPrivilege) {
          let controllerName = "", actionName = "";
          if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
              controllerName = to.meta.controllerName;
              actionName = to.meta.actionName;
          }
          else{
              let pathArray = to.path.split("/");
              if(pathArray.length > 2){
                  controllerName = pathArray[1];
                  actionName = pathArray[2];
              } else{
                  controllerName = pathArray[0];
                  actionName = pathArray[1];
              }
          }
          if(controllerName != "" && actionName != "") {
              DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                  if (value.data)
                      next()
                  else
                      //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                      next('/unauthorized');
                  },
                  (error) => {
                      console.log(error);
                  }
              );
          } else {
              next()
          }
        } else {
          next();
        }
      } else {
        authService.authenticate(to.path).then(() => {
          console.log("authenticating a protected url:" + to.path);
          next();
        });
      }
    }
  }
});
export default router

